body {
  background: $bg-light;
}

.header-stats {
  background: $bg-dark;
  .results {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem 5rem;
    text-align: center;
    article {
      @include sm {
        margin: 2rem 0;
      }
    }
  }
  h1 {
    font-size: 4rem;
    letter-spacing: 0.2rem;
  }

  h4 {
    color: $white;
    letter-spacing: 0.03rem;
    font-weight: 400;
  }
  .btn-link {
    color: $awaiting;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.15rem;

    &:hover,
    &:active,
    &:focus {
      color: $white;
    }
  }
}




.list-box {
  border-top: 3px solid ;
  background: $white;
  @include shadow;
}
.intro-text {
  width: 70%;
  h5 {
    line-height: 1.8rem;
  }
  @include lg {
    width: 100%;
  }
}
.table-section {
  width: 80%;
  padding-top: 3rem;
  margin: 0 auto;
  @include md {
    width: 90%;
  }
  th {
    vertical-align: middle;
    border: none;
    letter-spacing: 0.03rem;
  }
  td {
    vertical-align: middle;
  }
}
.list-group-pagination {
  justify-content: center;
  padding: 2rem;
}
.icon-group {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    min-width: 50px;
  }
}


.table {
  color: $secondary;
}

.box {
  padding: 0.5rem;
  margin: 0;
  &.title-box-primary {
    background: lighten($primary, 37%);
  }

  &.title-box-secondary {
    background: lighten($primary, 47%);
  }
  &.title-box-tertiary {
    background: lighten($alert, 37%);
  }
  &.title-box-quaternary {
    background: lighten($awaiting, 20%);
  }
  &.title-box-quinary {
    background: lighten($alert, 37%);
  }
}
.inline-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.header-info {
  font-size: 1.3rem;
  background: $white;
  @include shadow;
  padding: 1rem;
}
.subject-full-name {
  text-align: center;
  padding: 1rem;
}
.modal-content {
  padding: 2rem;
  .close {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 0.7;
  }
}

.login {
  padding: 4rem;
  text-align: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 33%;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $bg-light;
    opacity: 0.93;
  }

  .banner-text-group {
    width: 60vw;
    letter-spacing: 0.01rem;
    color: $secondary;
    .banner-text-item {
      font-weight: 100;
    }
  }
}

.box-section {
  @include lg {
    text-align: center;
  }
  .text-group,
  .image-group {
    display: flex;
    align-items: center;
    justify-content: center;
    .text-items {
      max-width: 85%;
      margin: 3rem 0 0 0;
      color: $white;
      justify-content: center;
      align-content: center;
      .subject {
        text-transform: uppercase;
        letter-spacing: 0.1rem;
      }
      .headline {
        font-size: 3rem;
        @include md {
          text-align: center;
          font-size: 2.2rem;
        }
      }

      .description {
        line-height: 1.7rem;
        letter-spacing: 0.05rem;
        font-weight: 400;
        span {
          font-weight: 600;
          color: $success;
        }
      }
    }
  }
}



.page-item {
  color: grey;
  cursor: pointer;
}