.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-container {
  display: flex;
}

.nav-bar {
  background-color: #c10719;
}

.logged-user {
  color: #58585a;
}

.error {
  color: #ed1c23;
}

.z-index-0 {
  z-index: 99999;
}

.pointer {
  cursor: pointer;
}

.thumbnail {
  height: 100px;
}

.margin-top-10px {
  margin-top: 10px;
}
.text-area {
  resize: none;
}

.height-70 {
  height: 70px;
}

.table-head {
  background-color: #0069b4;
  color: white;
}

.button-standard {
  background-color: #58585a;
  color: white;
}

.heading {
  color: #285598;
}

.black {
  color: black;
}

.declined-meeting-tr {
  background-color: #ed1c23;
  color: #ffffff;
}

.delete-button {
  background-color: #ee1c25;
}
.button-add-appointment {
  background-color: green;
  float: right;
  margin: auto;
  color: white;
}

.label-form {
  color: #58585a;
}

.dark-label {
  color: black;
}

.appointments-head4 {
  padding-left: 35px;
  padding-top: 10px;
}

.spinner {
  position: absolute;
  height: 90px;
  width: 90px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
}
